<template>
    <ul class="details-list">
        <li
            v-for="item in props.items"
            :key="item.title"
            class="details-list__item"
        >
            <BaseIcon
                class="details-list__icon"
                :icon="item.icon"
            />
            <div class="details-list__content">
                <h3>{{ item.title }}</h3>
                <p v-html="item.description" /> <!-- eslint-disable-line -->
            </div>
        </li>
    </ul>
</template>

<script setup>
const props = defineProps({
    items: {
        type: Array,
        default() {
            return [
                {
                    icon: 'camera',
                    title: 'Made by peers',
                    description: 'Learn from experts in your field'
                }
            ];
        }
    }
});
</script>

<style lang="less" src="./DetailsList.less"></style>
